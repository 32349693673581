import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import styles from './vatSwitcher.scss';
import SwitcherIcon from '../../icons/switcherIcon';

import { MainStore } from '../../../stores/MainStore';
import FragmentPlaceholder from '../../fragmentPlaceholder/fragmentPlaceholder';

const VatSwitcher = inject('store')(
    observer(({ store, isStickyHeader, showShippingInfo }: IVatSwitcherProps) => {
        const withVat = store?.orderModuleStore.withVat;

        if (store?.dataStore.contextData.customerData && store.dataStore.contextData.customerData.flagVatFree) {
            return null;
        }

        const buttonTestId = withVat ? 'inc-vat-button' : 'ex-vat-button';
        const buttonLabel = withVat ?
            store?.localization.generalL10n.withVatLocale : store?.localization.generalL10n.withoutVatLocale;

        function handleButtonClick() {
            const newWithVatValue = !withVat;
            store?.orderModuleStore.toggleWithVat();
            store?.orderModuleStore.setPriceModeCookie(newWithVatValue ? 'Gross' : 'Net');
        }

        const labelClassNames = classNames(styles.switcher_text, {
            [styles.sticky]: isStickyHeader,
            [styles.withVat]: !isStickyHeader && withVat,
        });
        const label = <span className={labelClassNames}>{buttonLabel}</span>;

        if (isStickyHeader) {
            return (
                <div className={styles.sticky_label}>
                    {label}
                    {showShippingInfo && (
                        <span
                            role='shipping-info-sticky'
                            className={classNames(labelClassNames, { [styles.shipping_info]: showShippingInfo })}
                        >
                            <FragmentPlaceholder src={'/shippingtext/details-page'} ignoreErrors={true} timeout={2} />
                        </span>
                    )}
                </div>
            );
        }

        const buttonClassNames = withVat ? [styles.switcher_button, styles.withVat].join(' ') : styles.switcher_button;
        const iconClassNames = withVat ? [styles.switcher_icon, styles.withVat].join(' ') : styles.switcher_icon;

        return (
            <div data-testid='vat-switcher' className={styles.switcher}>
                <button data-testid={buttonTestId} className={buttonClassNames} onClick={handleButtonClick}>
                    <span className={iconClassNames}>
                        <SwitcherIcon />
                    </span>
                </button>
                {label}
            </div>
        );
    })
);

export interface IVatSwitcherProps {
    store?: MainStore;
    isStickyHeader?: boolean;
    showShippingInfo?: boolean;
}

export default VatSwitcher;
