import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import styles from '../bannerModule.scss';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';
import DataStore from '../../../stores/DataStore';
import ShoesBannerHeader from '../../icons/shoes_banner_header';

const ShoesBanner = inject('dataStore')(
    observer((props: { dataStore?: DataStore }) => {
        const { dataStore } = props;

        if (
            !dataStore?.bannerModule.showShoes
        ) {
            return null;
        }

        return (
            <a
                data-testid='shoes_banner'
                className={styles.banner_item}
                href={dataStore.bannerModule.shoesRedirectUrl}
                target='_blank'
                rel='noreferrer noopener'
            >
                <div className={styles.header}>
                    <ShoesBannerHeader />
                    <p className={styles.header_banner_type}>
                        {dataStore.mainStore.localization.generalL10n.safetyClassesOverview}
                    </p>
                </div>
                <div className={classNames(styles.media_content, styles.media_content_shoes)} />
                <div data-testid='shoes_button' className={styles.footer}>
                    <div className={styles.button}>
                        <div className={styles.title}>{dataStore.mainStore.localization.generalL10n.discoverNow}</div>
                        <IconArrowExtraSmallRight />
                    </div>
                </div>
            </a>
        );
    })
);

export default ShoesBanner;
