import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import styles from '../bannerModule.scss';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';
import DataStore from '../../../stores/DataStore';
import GlovesPropertiesAndStandardsIcon from '../../icons/gloves_properties_and_standards_icon';

const GlovesBanner = inject('dataStore')(
    observer((props: { dataStore?: DataStore }) => {
        const { dataStore } = props;

        if (!dataStore?.bannerModule.showGloves) {
            return null;
        }

        return (
            <a
                data-testid='gloves_banner'
                className={styles.banner_item}
                href={dataStore.bannerModule.glovesRedirectUrl}
                target='_blank'
                rel='noreferrer noopener'
            >
                <div className={styles.header}>
                    <GlovesPropertiesAndStandardsIcon />
                    <p className={styles.header_banner_type}>
                        {dataStore.mainStore.localization.generalL10n.propertiesAndStandards}
                    </p>
                </div>
                <div className={classNames(styles.media_content, styles.media_content_gloves)} />
                <div data-testid='gloves_button' className={styles.footer}>
                    <div className={styles.button}>
                        <div className={styles.title}>{dataStore.mainStore.localization.generalL10n.discoverNow}</div>
                        <IconArrowExtraSmallRight />
                    </div>
                </div>
            </a>
        );
    })
);

export default GlovesBanner;
