import axios, { AxiosError, AxiosResponse } from 'axios';
import { observable, makeObservable, action, computed } from 'mobx';
import { IPrice } from './types';

import { MainStore } from './MainStore';
import defaultStyles from '../styles/defaults.scss';
import { AvailabilityState } from '../components/orderModule/deliveryTime/deliveryTime';
import { decodeHTML } from '../helper/html-helper';

function getWithVatValue(priceModeCookie?: 'Gross' | 'Net') {
    let result;

    switch (priceModeCookie) {
        case 'Gross':
            result = true;
            break;
        case 'Net':
            result = false;
            break;
        default:
            result =
                typeof document !== 'undefined'
                    ? (document.getElementsByClassName('current-pricemode-gross') || [{}]).length > 0
                    : true;
    }

    return result;
}

class OrderModuleStore {
    mainStore: MainStore;
    itemOrigin: string;
    mpcItemOrigin = 'DetailsPage';
    withVat: boolean;
    compareShareBookmarkMobileExpanded: boolean;
    articleCount: number | '';
    stickHeaderHeight = 0;
    readonly articleCountMin: number = 1;
    readonly articleCountMax: number = 9999;

    constructor(store: MainStore, initialPriceMode?: 'Gross' | 'Net') {
        this.mainStore = store;
        this.withVat = getWithVatValue(initialPriceMode);
        this.articleCount = 1;
        this.compareShareBookmarkMobileExpanded = false;

        makeObservable(this, {
            withVat: observable,
            articleCount: observable,
            stickHeaderHeight: observable,
            compareShareBookmarkMobileExpanded: observable,
            toggleWithVat: action.bound,
            setPriceModeCookie: action,
            setArticleCount: action.bound,
            setStickHeaderHeight: action.bound,
            toggleCompareShareBookmarkMobileExpanded: action.bound,
            basePriceString: computed,
            infoTexts: computed,
            articleUnit: computed,
            titleAddon: computed,
            deliveryTimeString: computed,
            deliveryTimeStatusColor: computed,
            isSale: computed,
            shouldRenderBasePrice: computed,
            shouldRenderInfoTexts: computed,
            soldOutArticleStatusColor: computed
        });

        // normal methods that don't change state
        this.getPriceString = this.getPriceString.bind(this);
        this.updateBasketItemCount = this.updateBasketItemCount.bind(this);
        this.onPriceModeChangedEvent = this.onPriceModeChangedEvent.bind(this);

        if (typeof window !== 'undefined') {
            window.shell.subscribeTo('PriceModeChanged', newPriceMode => {
                this.onPriceModeChangedEvent(newPriceMode);
            });
        }

        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.forEach((value, key) => {
                if (key.localeCompare('itemOrigin', undefined, { sensitivity: 'accent' }) === 0)
                    this.itemOrigin = value;
            });
            if (this.itemOrigin) this.mpcItemOrigin = this.itemOrigin;
        }
    }

    getPriceString(price?: IPrice): string {
        if (price) {
            let localizedPriceString: string;
            let priceString: string;
            if (this.mainStore.isGlobal) {
                priceString = price.netValue.toFixed(2);
            } else {
                priceString = (this.withVat ? price.grossValue : price.netValue).toFixed(2);
            }

            const priceStringSplit = priceString.split('.');

            let intString = priceStringSplit[0];
            let seperatedIntString = '';
            const numberGroupSeparator =  this.mainStore.dataStore.contextData.numberFormat.numberGroupSeparator;

            while (intString.length > 0) {
                if (intString.length > 3) {
                    const last3Digits = intString.slice(-3);
                    seperatedIntString =  numberGroupSeparator + last3Digits + seperatedIntString;
                } else {
                    seperatedIntString = intString + seperatedIntString;
                }
                intString = intString.slice(0, -3);
            }

            const numberDecimalSeparator =  this.mainStore.dataStore.contextData.numberFormat.numberDecimalSeparator;
            const fractionString = priceStringSplit[1];

            localizedPriceString = `${seperatedIntString}${numberDecimalSeparator}${fractionString}`;

            const currency = this.mainStore.dataStore.orderModule.articlePrice.currency.symbolShortForm;
            const prependCurrencySymbol =
                this.mainStore.dataStore.orderModule.articlePrice.currency.prependCurrencySymbol;

            if (prependCurrencySymbol)
                localizedPriceString = `${currency} ${localizedPriceString}`;
            else
                localizedPriceString = `${localizedPriceString} ${currency}`
            return localizedPriceString;
        } else {
            return '';
        }
    }

    updateBasketItemCount() {
        if (typeof window === 'undefined') return; //only client side
        const urlToHost = window.location.protocol + '//' + window.location.host;

        const basketApiUrl =
            urlToHost +
            this.mainStore.fetchedData.contextData.originalRootPath +
            'api/basket/Basket/BasketItemQuantities';

        const savKey = this.mainStore.fetchedData.orderModule.articleInfo.salesArticleVariantKey;
        this.mainStore.fetch
            .getBasketItemCount(basketApiUrl, savKey)
            .then(response => {
                this.setArticleCount(response);
            })
            .catch(() => {
                this.setArticleCount(1);
            });
    }

    onPriceModeChangedEvent(newPriceMode: 'Gross' | 'Net') {
        // update withVat if it is out of sync with app shell event value
        if ((newPriceMode === 'Gross' && !this.withVat) || (newPriceMode === 'Net' && this.withVat)) {
            this.toggleWithVat();
        }
    }

    toggleWithVat = () => {
        this.withVat = !this.withVat;
    };

    setPriceModeCookie(newPriceMode: 'Net' | 'Gross'): void {
        const urlToHost = window.location.protocol + '//' + window.location.host;
        const apiUrl = urlToHost + this.mainStore.dataStore.contextData.originalRootPath + 'ajax/update/Content/Empty2';
        const requestURL = new URL(apiUrl);

        requestURL.searchParams.set('PriceMode', newPriceMode);
        axios
            .get(requestURL.toString())
            .then((res: AxiosResponse) => {
                if (res.status === 200 && typeof window !== 'undefined') {
                    window.shell.publishTo('PriceModeChanged', newPriceMode);
                }
            })
            .catch((error: Error | AxiosError) => {
                const errorMsg = `${error.name}: ${error.message}`;
                const errorStack = error.stack;

                // eslint-disable-next-line no-console
                console.warn(`fetch() failed with "${errorMsg}". Full error:\n${errorStack}`);
            });
    }

    setArticleCount(param: number | ''): void {
        if (typeof param === 'number' && param > 0)
            this.articleCount = param;
        else
            this.articleCount = this.articleCountMin;
    }

    setStickHeaderHeight(param: number): void {
        this.stickHeaderHeight = param;
    }

    toggleCompareShareBookmarkMobileExpanded(): void {
        this.compareShareBookmarkMobileExpanded = !this.compareShareBookmarkMobileExpanded;
    }

    get basePriceString() {
        let result = '';
        const basicPrice = this.mainStore.dataStore.orderModule.articlePrice.basicPrice;
        if (basicPrice.basePrice) {
            const priceString = this.getPriceString({
                grossValue: basicPrice.basePrice.grossValue,
                netValue: basicPrice.basePrice.netValue,
            });
            result = priceString;
        }
        return [result, basicPrice?.basePrice?.unit];
    }

    get infoTexts() {
        let infoTextResult: string[][] = [];
        const { priceFromLocale } = this.mainStore.localization.generalL10n;
        const { articlePrice, articleInfo } = this.mainStore.dataStore.orderModule;
        if (Array.isArray(articlePrice.scalePrices)) {
            infoTextResult = articlePrice.scalePrices.map((item) => {
                const unit =
                    item.quantity > 1
                        ? articleInfo.packagingInformation.quantityUnit.plural
                        : articleInfo.packagingInformation.quantityUnit.singular;
                const preString = `${priceFromLocale} ${item.quantity} ${unit}: `;
                let basePricePreString = '';
                let basePriceString = '';
                let basePriceUnit = '';

                if (item.price.basePrice) {
                    basePriceUnit = item.price.basePrice.unit ? `/${item.price.basePrice.unit}` : '';
                    basePriceString = this.getPriceString(item.price.basePrice);
                    basePricePreString = ` | ${this.mainStore.localization.generalL10n.basePriceLocale}: `;
                }

                return [preString, this.getPriceString(item.price),
                    basePricePreString, basePriceString, basePriceUnit];
            });
        }
        return infoTextResult;
    }

    get titleAddon(): string {
        let result = '';

        const packagingInformation = this.mainStore.dataStore.orderModule.articleInfo.packagingInformation;
        if (packagingInformation.packageSize > 1) {
            let quantity = packagingInformation.quantityUnit.plural;
            let subQuantityUnit = '';
            if (packagingInformation.subQuantityUnit) {
                subQuantityUnit = `${packagingInformation.subQuantityUnit.plural} / `;
                quantity = packagingInformation.quantityUnit.singular;
            }
            result = `${packagingInformation.packageSize} ${subQuantityUnit}${quantity}`;
        }

        return result;
    }

    get deliveryTimeString(): string {
        const {
            periodType,
            minValue,
            maxValue,
            calendarWeek,
        } = this.mainStore.dataStore.orderModule.deliveryInformation;

        let deliveryTimeLocale;
        let result;
        if (!this.mainStore.isGlobal) {
            switch (periodType) {
                case 1:
                    deliveryTimeLocale = this.mainStore.localization.generalL10n.deliveryTimeUnknownDateLocale;
                    break;
                case 2:
                    deliveryTimeLocale = this.mainStore.localization.generalL10n.deliveryTimeFromCalWeekLocale;
                    break;
                default:
                    deliveryTimeLocale = this.mainStore.localization.generalL10n.deliveryTimeKnownDateLocale;
            }

            if (typeof window != 'undefined') {
                result =
                    periodType === 2
                        ? decodeHTML(deliveryTimeLocale) + ' ' + String(calendarWeek)
                        : decodeHTML(deliveryTimeLocale)?.replace('{0}', String(minValue))
                            .replace('{1}', String(maxValue));
            } else {
                result =
                    periodType === 2
                        ? deliveryTimeLocale + String(calendarWeek)
                        : `~~~${deliveryTimeLocale}~${String(minValue)}~${String(maxValue)}~~~`;
            }
        } else {
            switch (this.mainStore.dataStore.orderModule.availabilityState) {
                case AvailabilityState.InStock:
                    deliveryTimeLocale = this.mainStore.localization.glbOnlyL10n?.deliveryDateShortGLBLocale;
                    break;
                case AvailabilityState.SoonInStock:
                    deliveryTimeLocale = this.mainStore.localization.glbOnlyL10n?.deliveryDateLongGLBLocale;
                    break;
                default:
                    deliveryTimeLocale = this.mainStore.localization.glbOnlyL10n?.deliveryDateLongGLBLocale;
            }
            result = typeof window != 'undefined' ? decodeHTML(deliveryTimeLocale) : deliveryTimeLocale;
        }
        return result;
    }

    get deliveryTimeStatusColor(): string {
        let statusColor;

        switch (this.mainStore.dataStore.orderModule.availabilityState) {
            case AvailabilityState.InStock:
                statusColor = defaultStyles.dpGreen;
                break;
            case AvailabilityState.SoonInStock:
                statusColor = defaultStyles.dpOrange;
                break;
            default:
                statusColor = defaultStyles.dpRed;
        }

        return statusColor;
    }

    get soldOutArticleStatusColor(): string {
        return defaultStyles.dpRed;
    }

    get isSale(): boolean {
        return !!this.mainStore.dataStore.orderModule.articlePrice.minimalOriginalPrice;
    }

    get shouldRenderBasePrice(): boolean {
        return !!this.mainStore.dataStore.orderModule.articlePrice.basicPrice.basePrice;
    }

    get shouldRenderInfoTexts() {
        return (
            !this.mainStore.dataStore.orderModule.articlePrice.hideScalePrices &&
            !(!this.infoTexts || this.infoTexts.length === 0)
        );
    }

    get articleUnit() {
        const packagingInformation = this.mainStore.dataStore.orderModule.articleInfo.packagingInformation;

        return this.articleCount > 1
            ? packagingInformation.quantityUnit.plural
            : packagingInformation.quantityUnit.singular;
    }

    get basketModalData(): IBasketModal {
        const articleCount =
            typeof this.mainStore.orderModuleStore.articleCount === 'number' &&
                this.mainStore.orderModuleStore.articleCount > 0
                ? this.mainStore.orderModuleStore.articleCount
                : this.articleCountMin;
        const basketModalItemOrigin = this.itemOrigin
            ? this.itemOrigin
            : 'NAVIGATION/' + this.mainStore.dataStore.breadcrumb.category.name;
        const returnValue: IBasketModal = {
            color: this.mainStore.variantStore.selectedColor.code.toString(),
            colorName: this.mainStore.variantStore.selectedColor.name,
            imageUrl: this.mainStore.mixedMediaStore.mainImage?.fullPath,
            quantity: articleCount,
            salesVariantKey: this.mainStore?.variantStore.selectedSize?.salesArticleVariantKey,
            masterArticleNo: this.mainStore?.dataStore.orderModule.articleInfo.masterArticleNo.toString(),
            size: this.mainStore?.variantStore.selectedSize?.size.name,
            title: this.mainStore.dataStore.orderModule.articleInfo.title,
            maxQuantityPerOrder: this.mainStore.dataStore.orderModule.maxQuantityPerOrder,
            quantityUnit:
                this.mainStore.dataStore.orderModule.maxQuantityPerOrder > 1
                    ? this.mainStore.dataStore.orderModule.articleInfo.packagingInformation.quantityUnit.plural
                    : this.mainStore.dataStore.orderModule.articleInfo.packagingInformation.quantityUnit.singular,
            model: this.mainStore.variantStore.selectedModel?.code.toString(),
            modelLabel: this.mainStore.variantStore.selectedModel?.label,
            itemOrigin: basketModalItemOrigin,
            nativeNavKeyPath: this.mainStore.dataStore.breadcrumb.category.navigationKeyPath,
            salesArticleNo: this.mainStore.dataStore.orderModule.articleInfo.salesArticleNo,
            grossPrice: this.mainStore.dataStore.orderModule.articlePrice.basicPrice.grossValue.toString(),
            netPrice: this.mainStore.dataStore.orderModule.articlePrice.basicPrice.netValue.toString(),
            isStraussBrand: this.mainStore.dataStore.orderModule.articleInfo.brand ?
                    this.mainStore.dataStore.orderModule.articleInfo.brand.toLowerCase() === this.mainStore.esBrand : false
        };        

        if (articleCount > 1) {
            const scaledPrice = this.mainStore.dataStore.orderModule.articlePrice.scalePrices;
            let returnPrice = '';
            scaledPrice?.forEach(price => {
                if (articleCount >= price.quantity)
                    returnPrice = price.price.netValue.toString();
            });
            returnValue.scalePrice = returnPrice;
        }
        return returnValue;
    }
}

interface IBasketModal {
    title: string;
    color?: string;
    colorName?: string;
    size?: string;
    model?: string;
    modelLabel?: string;
    imageUrl: string;
    salesVariantKey?: string;
    masterArticleNo: string;
    quantity: number;
    maxQuantityPerOrder?: number;
    quantityUnit?: string;
    itemOrigin?: string;
    nativeNavKeyPath?: string;
    salesArticleNo?: string;
    grossPrice?: string;
    netPrice?: string;
    scalePrice?: string;
    isStraussBrand?: boolean;
}

export default OrderModuleStore;
