import React from 'react';
import { ScrollLock } from '../../../helper/scrollLock';
import { ICareLabel } from '../../../stores/types';
import CrossRing from '../../icons/crossRing';
import styles from './careLabels.scss';

export default class CareLabels extends React.Component<
    { careLabels: ICareLabel[], label?: string, isMobileShop?: boolean },
    { careLabels: ICareLabel[], clIconSrc: string, clDescription: string }> {
    
    private readonly scrollLock: ScrollLock;
    private clOverlayRef = React.createRef<HTMLDivElement>();
    private clOverlayBackgroundRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
        this.scrollLock = ScrollLock.instance;

        this.state = {
            careLabels: this.props.careLabels,
            clIconSrc: '',
            clDescription: ''
        };

        this.openLabelOverlay = this.openLabelOverlay.bind(this);
        this.closeLabelOverlay = this.closeLabelOverlay.bind(this);
    }

    public update(careLabels: ICareLabel[]): void {
        this.setState({ careLabels: careLabels });
    }

    private openLabelOverlay(careLabel: ICareLabel): void {
        if(this.clOverlayRef.current) {
            this.setState({
                clIconSrc: careLabel.icon,
                clDescription: careLabel.description 
            }, ()=> {
                this.scrollLock.lock();
                this.clOverlayBackgroundRef.current?.classList.add(styles.show);
                this.clOverlayRef.current?.classList.add(styles.display_it);
            });
        }
    }

    private closeLabelOverlay(): void {
        this.setState({
            clIconSrc: '',
            clDescription: '' 
        }, ()=> {
            this.scrollLock.unlock();
            this.clOverlayRef.current?.classList.remove(styles.display_it);
            this.clOverlayBackgroundRef.current?.classList.remove(styles.show);
        }); 
    }

    public render() {
        return (
            (this.state.careLabels && this.state.careLabels.length > 0) &&
            <div className={styles.careLabels} data-testid='careLabels'>
                    {this.props.isMobileShop &&
                        <div className={styles.cl_overlay_background} 
                        ref={this.clOverlayBackgroundRef}></div>
                    }
                    {this.props.isMobileShop &&
                        <div className={styles.cl_overlay} ref={this.clOverlayRef}>
                            <div className={styles.o_crossIcon} onClick={this.closeLabelOverlay}>
                                <CrossRing />
                            </div>
                            <img className={styles.cl_icon} data-testid='cl_icon'
                                src={this.state.clIconSrc} />
                            <span className={styles.cl_description}>
                                {this.state.clDescription}
                            </span>
                        </div>
                    }
                <div className={styles.cl_title} data-testid='cl_title'>{this.props.label}</div>
                {
                    this.state.careLabels.map((careLabel: ICareLabel, index: number) => {
                        return (
                            <div className={styles.careLabel} key={index} data-testid='careLabel'>
                                <div className={styles.cl_icon_placeholder}
                                    onClick={() => { this.openLabelOverlay(careLabel) }}>
                                    <img className={styles.cl_icon} data-testid='cl_icon'
                                        src={careLabel.icon} />
                                </div>
                                <span className={styles.cl_description}>{careLabel.description}</span>
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}
