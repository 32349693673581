import React from 'react';
import { ILocalization } from '../../../types';
import { IResponseData } from '../../../stores/types';
import Cross from '../../icons/cross';
import styles from './detailsModal.scss';
import classNames from 'classnames';
import { DetailsModalContent } from './detailsModalContent/detailsModalContent';
import ArrowSmallIcon from '../../icons/arrowSmallIcon';
function DetailsModal({ articleSetItemDetail, localization, isOpen, onClose, variantLabel, detailPageUrl }: IDetailsModal) {
    const detailsModalRef = React.useRef<HTMLDivElement>(null);
    if (!isOpen) {
        return <></>;
    }

    function redirectToDetailPage() {
        window.location.href = detailPageUrl;
    }

    return (
        <div className={styles.details_modal} data-testid='details_modal_root'>
            <div ref={detailsModalRef} className={styles.details_modal_container} data-testid='details_modal_container'>
                <div className={styles.details_modal_content} data-testid='details_modal_content'>
                    <div className={styles.details_modal_header} data-testid='details_modal_header'>
                        <div>
                            <span className={styles.details_modal_title} data-testid='details_modal_title'>
                                {articleSetItemDetail?.orderModule.articleInfo.title}
                            </span>
                            <div className={styles.color_size_info} data-testid='details_modal_color_size_info'>
                                {variantLabel}
                            </div>
                        </div>
                        <div className={styles.close_button} id='article_sets_details_modal_close_button' onClick={onClose}>
                            <Cross />
                        </div>
                    </div>
                    <div className={styles.details_modal_middle} data-testid='details_modal_middle'>
                        <DetailsModalContent data={articleSetItemDetail} />
                    </div>
                    <div className={styles.transition_on_scroll_down} />
                    <div className={styles.details_modal_footer} data-testid='details_modal_footer'>
                        <div className={classNames(styles.footer_button, styles.white)}
                            id='to_details_page_button'
                            // eslint-disable-next-line max-len
                            onClick={() => redirectToDetailPage()}>
                            <div className={styles.to_details_page}>
                                {localization?.generalL10n.toTheDetailsPage}
                            </div>
                            <ArrowSmallIcon />
                        </div>
                        <div className={classNames(styles.footer_button, styles.grey)}
                            id='close_details_modal_button'
                            onClick={onClose}>
                            {localization?.generalL10n.buttonClose}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface IDetailsModal {
    localization: ILocalization;
    isOpen: boolean;
    articleSetItemDetail: IResponseData;
    variantLabel: string;
    detailPageUrl: string;
    onClose?();
}

export default DetailsModal;
