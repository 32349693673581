import React, { useRef } from 'react';
import classNames from 'classnames';
import WorkWearStoreStore from '../../../../stores/WorkWearStoreStore';
import styles from '../workwearStore.scss';
import { CustomSwiper } from '../../../variantPanel/customeSwiper/customeSwiper';
import defaultStyles from '../../../../styles/defaults.scss';

const breakpointMobileMinWidth = parseInt(defaultStyles.breakpointMobileMinWidth) - 1;

function WorkwearStoreColors(props: IWorkwearStoreColors) {
    const { workWearStoreStore, showColors, showSizes, swiperDirection, width } = props;

    if (!showColors) {
        return null;
    }

    const selectedColorElementRef = useRef<HTMLInputElement>(null);

    const isSizesShown = {
        [styles.sizes_shown]: showSizes,
        [styles.sizes_not_shown]: !showSizes,
    };

    function handleColorClick(event) {
        const colorCode = event.currentTarget.dataset.colorcode;
        const selectedColorInternal = workWearStoreStore.availableColors.find(
            availableColor => availableColor.code == parseInt(colorCode)
        );
        if (selectedColorInternal)
            workWearStoreStore.changeSelectedColor(selectedColorInternal);
    }

    let colorsInRow = showSizes ? 4 : 8;
    colorsInRow = width >= 769 ? colorsInRow : 1;
    let colorSwipeable = true;
    let selectedColorIndex;

    if (workWearStoreStore.availableColors instanceof Array) {
        selectedColorIndex = workWearStoreStore.availableColors.findIndex(color => {
            return color.code === workWearStoreStore.selectedColor?.code;
        });
        selectedColorIndex = Math.floor(selectedColorIndex / colorsInRow);

        colorSwipeable =
            (width < breakpointMobileMinWidth && workWearStoreStore.availableColors.length >= 4) ||
            (width >= breakpointMobileMinWidth && workWearStoreStore.availableColors.length >= colorsInRow * 3);
    }

    return (
        <div role='color_content' className={classNames(styles.color_content, isSizesShown)}>
            <div
                role='color_icons'
                className={classNames(styles.color_icons, {
                    [styles.vertical]: swiperDirection === 'vertical',
                    [styles.disabled]: !colorSwipeable,
                    [styles.size_types_shown]: workWearStoreStore.availableSizeTypes.length > 1,
                })}
            >
                <CustomSwiper
                    type='wws-color'
                    slidesPerColumn={colorsInRow}
                    selectedElementIndex={selectedColorIndex}
                    direction={swiperDirection}
                    mousewheel={swiperDirection === 'vertical'}
                    enabled={colorSwipeable}
                    isWWS={true}
                >
                    {workWearStoreStore.availableColors.map((availableColor, index) => (
                        <React.Fragment key={index}>
                            {workWearStoreStore.selectedColor?.code === availableColor.code ? (
                                <div
                                    ref={selectedColorElementRef}
                                    className={classNames(styles.color_border, 'pdp-selected')}
                                >
                                    <img
                                        className={styles.color_icon}
                                        src={availableColor.iconPath}
                                        data-colorcode={availableColor.code}
                                        title={availableColor.name}
                                        onClick={handleColorClick}
                                    />
                                </div>
                            ) : (
                                <div className={styles.color_border}>
                                    <img
                                        className={styles.color_icon}
                                        src={availableColor.iconPath}
                                        data-colorcode={availableColor.code}
                                        title={availableColor.name}
                                        onClick={handleColorClick}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </CustomSwiper>
            </div>
        </div>
    );
}

interface IWorkwearStoreColors {
    workWearStoreStore: WorkWearStoreStore;
    showSizes: boolean;
    showColors: boolean;
    swiperDirection: 'horizontal' | 'vertical';
    width: number;
}

export default WorkwearStoreColors;
