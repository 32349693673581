import React, { useEffect } from 'react';
import ProductDescription from '../../../productInfo/description/productDescription';
import styles from './detailsModalContent.scss';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import Extras from '../../../productInfo/extras/extras';
import * as helper from '../../../../helper/html-helper';
import Bullets from '../../../productInfo/bullets/bullets';
import Materials from '../../../productInfo/materials/materials';
import CareLabels from '../../../productInfo/careLabels/careLabels';
import ShoeWeights from '../../../productInfo/shoeWeights/shoeWeights';
import { IInjectedProps } from '../../../../types';
import { IResponseData } from '../../../../stores/types';
import ArticleSetItem from '../../articleSetItem/articleSetItem';

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const DetailsModalContent = inject('store')(
    observer(({ data, store }: IDetailsModalProps) => {
        const productDescriptionRef = React.useRef<ProductDescription>(null);
        const bulletsRef = React.useRef<Bullets>(null);
        const productContentRef = React.useRef<HTMLDivElement>(null);
        const productImageRef = React.useRef<HTMLImageElement>(null);
        const extrasRef = React.useRef<Extras>(null);
        const shoeWeightsRef = React.useRef<ShoeWeights>(null);
        const materialsRef = React.useRef<Materials>(null);
        const careLabelsRef = React.useRef<CareLabels>(null);

        const structuredText = data.shutterModule.productInfo.description.bullets;
        const injectedRichText = data.allRichTexts;
        const orderedDisclaimers = data.shutterModule.productInfo.description.orderedDisclaimers;
        const commonRtfText = data.shutterModule.productInfo.description.commonRtfTextFromWsd;
        const materials = data.shutterModule.productInfo.description.materials;
        const careLabels = data.shutterModule.productInfo.description.careInstructions;
        const shoeWeights = data.shutterModule.productInfo.description.shoeWeights;
        const functionalLayers = data.shutterModule.productInfo.description.functionLayers;
        const articleSetItem = data.shutterModule.productInfo.description.articleSetItemDescriptions;

        const productInfo: IDetailsModalContent = {
            tabs: [
                {
                    title: helper.decodeHTML(store?.localization.generalL10n.briefDescription),
                    content: (
                        <>
                            {(articleSetItem && articleSetItem.length > 0) ?
                                <div>
                                    <div className={styles.title_set_consists_of} data-testid='dm_title_set_consists_of'>
                                        {helper.decodeHTML(store?.localization.generalL10n.articelSetConsistsOf)}
                                    </div>
                                    <div data-testid='dm_article_sets_wrapper'>
                                        {
                                            articleSetItem?.map((articleSetItem, index) => {

                                                return (
                                                    <ArticleSetItem key={index} articleSetItem={articleSetItem}
                                                        localization={store?.localization} showDetailsButton={false}
                                                        variantLabel={'### placeholder for color size label ###'} />
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                <div>
                                    {
                                        (structuredText && structuredText.id) ?
                                            <Bullets
                                                ref={bulletsRef}
                                                structuredTextId={structuredText.id}
                                                injectedRichTexts={injectedRichText} />
                                            :
                                            <ProductDescription
                                                ref={productDescriptionRef}
                                                functionalLayersExists={functionalLayers && functionalLayers.length > 0}
                                                description={commonRtfText}
                                                disclaimersExists={orderedDisclaimers && orderedDisclaimers.length > 0}
                                            />
                                    }
                                    <Materials
                                        ref={materialsRef}
                                        label={store?.localization.generalL10n.materialLabel}
                                        materials={materials}
                                        grammageLabel={store?.localization.generalL10n.grammage}
                                        portalMandantToken={store?.dataStore.contextData.portalMandantToken}
                                    />
                                    <CareLabels
                                        ref={careLabelsRef}
                                        label={store?.localization.generalL10n.careLabel}
                                        careLabels={careLabels}
                                        isMobileShop={store?.isMobileShop}
                                    />
                                    <ShoeWeights
                                        ref={shoeWeightsRef}
                                        shoeWeights={shoeWeights}
                                        gramsInSizeLbl={store?.localization.generalL10n.gramsInSize}
                                        approxWeightLbl={store?.localization.generalL10n.approxWeight}
                                    />
                                </div>
                            }
                        </>

                    ),
                },
                {
                    title: helper.decodeHTML(store?.localization.generalL10n.extras),
                    content: (
                        <Extras
                            ref={extrasRef}
                            extras={data.shutterModule.productInfo.extras}
                            debuggerIsOn={store!.debuggerIsOn}
                            updateProductInfoContentHeight={updateAdditionalLayout}
                            injectedRichText={injectedRichText}
                        />
                    ),
                },
            ],
        };

        /* eslint-disable*/
        function updateAdditionalLayout() {
        }

        const [activeTab, setActiveTab] = React.useState(productInfo.tabs[0]);

        useEffect(() => {
            if (productDescriptionRef && productDescriptionRef.current && productImageRef) {
                productDescriptionRef.current.update(
                    commonRtfText, functionalLayers && functionalLayers.length > 0,
                    orderedDisclaimers && orderedDisclaimers.length > 0
                );
            }

            if (shoeWeightsRef && shoeWeightsRef.current) {
                shoeWeightsRef.current.update(shoeWeights);
            }

            if (extrasRef && extrasRef.current) {
                if (data.shutterModule.productInfo.extras.length === 0 &&
                    activeTab.title === helper.decodeHTML(store?.localization.generalL10n.extras)) {
                    setActiveTab(productInfo.tabs[0])
                    return;
                }

                extrasRef.current.updateExtras(data.shutterModule.productInfo.extras);
                extrasRef.current.turnOnDebugger(store!.debuggerIsOn);
            }

            if (bulletsRef && bulletsRef.current && structuredText && structuredText.id)
                bulletsRef.current.updateBullets(structuredText.id);

            if (careLabelsRef && careLabelsRef.current) {
                careLabelsRef.current.update(careLabels);
            }

            if (materialsRef && materialsRef.current) {
                materialsRef.current.update(materials);
            }
        });

        return (
            <>
                <div className={styles.product_info_details_container} >
                    <div className={styles.product_image} data-testid='dm_article_set_product_image'>
                        <img alt={helper.decodeHTML(data.mixedMedia.altTagForImages) ?? ''}
                            ref={productImageRef} src={data.mixedMedia.image.fullPath} />
                    </div>
                    <div className={styles.product_info_tabs} data-testid='dm_article_set_product_info_tabs'>
                        <div
                            className={classNames(
                                styles.product_info_tabs_header,
                                { [styles.no_extras]: data.shutterModule.productInfo.extras.length === 0 },
                                { [styles.no_specs]: data.shutterModule.productInfo.details.length === 0 }
                            )}
                        >
                            {productInfo.tabs.map(tab => {
                                if (articleSetItem && articleSetItem.length > 0
                                    && tab.title === helper.decodeHTML(store?.localization.generalL10n.extras))
                                    return null;
                                return (
                                    <div
                                        key={tab.title}
                                        id='details_modal_info_tab'
                                        className={classNames(styles.product_info_tab, {
                                            [styles.active]: tab.title === activeTab.title,
                                        })}
                                        onClick={() => {
                                            if (tab.title !== activeTab.title)
                                                setActiveTab(tab)
                                        }
                                        }
                                    >
                                        {tab.title}
                                    </div>
                                );
                            })}
                        </div>

                        <div className={styles.dm_product_info_content}
                            data-testid='dm_article_set_product_info_content'
                            role='content'
                            ref={productContentRef}
                        >
                            {activeTab.content}
                        </div>
                    </div>
                </div>
            </>
        );
    })
);

interface IDetailsModalContent {
    tabs: IDetailsModalContentTab[];
}

interface IDetailsModalProps extends IInjectedProps {
    data: IResponseData;
}

interface IDetailsModalContentTab {
    title?: string;
    content: React.ReactNode;
}
