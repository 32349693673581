import React from 'react';
import classNames from 'classnames';
import * as helper from '../../../helper/html-helper';
import AASLogo from '../../icons/aas_logo';
import styles from './headerTab.scss';

export default class HeaderTab extends React.Component<{
    logoSrc?: string, title?: string,
    description?: string, tabKey: string,
    onTabChange, activeTab: string, cdnAssetsUrl?: string
    },
    { logoSrc?: string }> {

    constructor(props) {
        super(props);
        this.state = { logoSrc: undefined };
    }

    componentDidMount() {
        if (this.props.logoSrc && this.props.cdnAssetsUrl)
            this.setState(
                { logoSrc: helper.formCdnUrl(this.props.logoSrc, this.props.cdnAssetsUrl) }
            );
    }

    public render() {
        return (
            <div className={classNames(styles.tab_container,
                { [styles.isActive]: this.props.tabKey === this.props.activeTab})}
                onClick={this.props.onTabChange}
                data-tabkey={this.props.tabKey}
                data-testid='pa_header_tab' >
                <div className={styles.t_logo}>
                {
                        this.state.logoSrc ?
                            <img className={classNames(styles.logo_img, styles.tf_logo)}
                                src={this.state.logoSrc} />
                            :
                            <div className={classNames(styles.logo_img, styles.aas_logo)}>
                                <AASLogo />
                            </div>
                    }
                </div>
                <div className={styles.t_text_area}>
                    <div className={styles.t_title}>{
                        helper.decodeHTML(this.props.title)
                    }</div>
                    <div className={styles.t_description}>{
                        helper.decodeHTML(this.props.description)
                    }</div>
                </div>
            </div>
        );
    }
}
