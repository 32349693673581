import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';

import styles from '../bannerModule.scss';
import IconArrowExtraSmallRight from '../../icons/icon_arrow_extra_small_right';
import DataStore from '../../../stores/DataStore';
import LogoService from '../../icons/logoservice';
import * as helper from '../../../helper/html-helper';

const LogoServiceBanner = inject('dataStore')(
    observer((props: { dataStore?: DataStore }) => {
        const { dataStore } = props;

        if (!dataStore?.bannerModule.showLogoService) {
            return null;
        }

        return (
            <div
                data-testid='logo_service_banner'
                className={styles.banner_item}
                onClick={()=>{ 
                    dataStore.contextData.featureToggles.enableLogoCreator &&
                        (dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ?? false) ? 
                    dataStore?.mainStore.showPersonalizeOverlay(true)
                    :
                    window.open(dataStore.bannerModule.logoServiceRedirectUrl, '_self')
                }}
            >
                <div className={styles.header}>
                    <LogoService />
                    <p className={styles.header_banner_type}>
                        {dataStore.contextData.featureToggles.enableLogoCreator &&
                            (dataStore.shutterModule.productInfo?.description?.isSmakeDesignerAvailable ?? false) ?  
                        helper.decodeHTML(dataStore.mainStore.localization.generalL10n.personalise) : 
                        helper.decodeHTML(dataStore.mainStore.localization.generalL10n.logoService)  }
                    </p>
                </div>
                <div className={classNames(styles.media_content, styles.media_content_logo_service)} />
                <div data-testid='logo_service_button' className={styles.footer}>
                    <div className={styles.button}>
                        <div className={styles.title}>{dataStore.mainStore.localization.generalL10n.discoverNow}</div>
                        <IconArrowExtraSmallRight />
                    </div>
                </div>
            </div>
        );
    })
);

export default LogoServiceBanner;
